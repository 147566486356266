.pn-status__serverSuccess {
  position: relative;
  padding: 1em;
  min-height: 2.4em;
  background: #43ac6a;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 1.5em;
  max-width: 34rem;
  margin: 0 auto 1em;

  @include grid-media-query('small-tablet-and-up') {
    &::before {
      left: auto;
    }
  }
}

.pn-status__serverSuccessTitle {
  color: #1c462c;
  font-weight: bold;
}

.pn-status__serverError {
  position: relative;
  padding: 1em;
  min-height: 2.4em;
  background: #ffb22a;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 1.5em;
  max-width: 34rem;
  margin: 0 auto 1em;

  @include grid-media-query('small-tablet-and-up') {
    &::before {
      left: auto;
    }
  }
}

.pn-status__serverErrorTitle {
  color: #824700;
  font-weight: bold;
}

.pn-status__serverSuccessText,
.pn-status__serverErrorText {
  color: white;

  a {
    color: #c34d00;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
