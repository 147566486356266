.pn-form__field {
  margin-bottom: 1em;

  input {
    -webkit-box-shadow: inset 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 2px 2px 1px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
  }

  input:not([type='checkbox']),
  .pn-form__select {
    display: inline-block;
    width: 100%;
    max-width: 36rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #303030;
    background: #fff;
    box-sizing: border-box;
  }

  input:not([type='checkbox']) {
    padding: 0.75rem 0.5625rem;
  }

  .pn-form__select {
    cursor: pointer;
    position: relative;

    select {
      width: 100%;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid #ccc;
      cursor: pointer;
      background: none;
      padding: 0.75rem 0.5625rem;
    }

    &::after {
      content: '▼';
      position: absolute;
      right: 1em;
      top: 25%;
      color: #303030;
      pointer-events: none;
    }
  }

  &:last-of-type {
    margin-bottom: 2em;
  }
}

.field_with_errors {
  border-radius: 0.25rem;
  border: none;

  input,
  select {
    background: #ffefd8 !important;
    border-color: rgb(255, 178, 42) !important;
  }
}

.pn-form__holder {
  max-width: 29rem;
  margin: 0 auto;
}

.pn-form__checklist {
  margin: 0 auto;
  color: $c-columnHeader-color;
  font-size: 0.75rem;
  text-align: left;
  line-height: 0.85rem;

  .pn-form__label {
    cursor: pointer;

    input {
      cursor: pointer;
      position: relative;
      width: 2em;
      height: 2em;
      background: white;
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      border-radius: 0.2em;
      box-sizing: border-box;
      padding: 0;
      outline: none;

      &:checked::after {
        content: '\f00c';
        font-family: 'FontAwesome';
        font-weight: 400;
        font-size: 1rem;
        text-rendering: optimizeLegibility;
        display: block;
        position: absolute;
        top: 0.2em;
        left: 0.225em;
        color: #00bbe3;
        pointer-events: none;
      }
    }
  }
}

.pn-form__fontawesomePreload {
  color: $c-contentArea-bg;
  visibility: hidden;
  position: absolute;
}

.pn-form__finePrint {
  color: #666;
}

.pn-form__links {
  margin: 1rem 0 0;
  font-size: 1rem;
}

@-moz-document url-prefix() {
  .pn-form__checklist {
    .field_with_errors {
      background: #ffefd8 !important;
      border: 1px solid rgb(255, 178, 42);

      input {
        background: transparent;
        border: none;
      }
    }
  }
}
