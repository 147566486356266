.p0 {
  padding: 0 !important;
}
.p05 {
  padding: 0.25rem !important;
}
.p1 {
  padding: 0.5rem !important;
}
.p2 {
  padding: 1rem !important;
}
.p3 {
  padding: 2rem !important;
}
.p4 {
  padding: 4rem !important;
}

.pt0 {
  padding-top: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.px0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pt05 {
  padding-top: 0.25rem !important;
}
.pr05 {
  padding-right: 0.25rem !important;
}
.pb05 {
  padding-bottom: 0.25rem !important;
}
.pl05 {
  padding-left: 0.25rem !important;
}
.py05 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.px05 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.pt1 {
  padding-top: 0.5rem !important;
}
.pr1 {
  padding-right: 0.5rem !important;
}
.pb1 {
  padding-bottom: 0.5rem !important;
}
.pl1 {
  padding-left: 0.5rem !important;
}
.py1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.px1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.pt2 {
  padding-top: 1rem !important;
}
.pr2 {
  padding-right: 1rem !important;
}
.pb2 {
  padding-bottom: 1rem !important;
}
.pl2 {
  padding-left: 1rem !important;
}
.py2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.px2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.pt3 {
  padding-top: 2rem !important;
}
.pr3 {
  padding-right: 2rem !important;
}
.pb3 {
  padding-bottom: 2rem !important;
}
.pl3 {
  padding-left: 2rem !important;
}
.py3 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.px3 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.pt4 {
  padding-top: 4rem !important;
}
.pr4 {
  padding-right: 4rem !important;
}
.pb4 {
  padding-bottom: 4rem !important;
}
.pl4 {
  padding-left: 4rem !important;
}
.py4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.px4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.m0 {
  margin: 0 !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mr0 {
  margin-right: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}
.mx0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m05 {
  margin: 0.25rem !important;
}
.mt05 {
  margin-top: 0.25rem !important;
}
.mr05 {
  margin-right: 0.25rem !important;
}
.mb05 {
  margin-bottom: 0.25rem !important;
}
.ml05 {
  margin-left: 0.25rem !important;
}
.mx05 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.my05 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.m1 {
  margin: 0.5rem !important;
}
.mt1 {
  margin-top: 0.5rem !important;
}
.mr1 {
  margin-right: 0.5rem !important;
}
.mb1 {
  margin-bottom: 0.5rem !important;
}
.ml1 {
  margin-left: 0.5rem !important;
}
.mx1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.my1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m2 {
  margin: 1rem !important;
}
.mt2 {
  margin-top: 1rem !important;
}
.mr2 {
  margin-right: 1rem !important;
}
.mb2 {
  margin-bottom: 1rem !important;
}
.ml2 {
  margin-left: 1rem !important;
}
.mx2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.my2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.m3 {
  margin: 2rem !important;
}
.mt3 {
  margin-top: 2rem !important;
}
.mr3 {
  margin-right: 2rem !important;
}
.mb3 {
  margin-bottom: 2rem !important;
}
.ml3 {
  margin-left: 2rem !important;
}
.mx3 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.my3 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.m4 {
  margin: 4rem !important;
}
.mt4 {
  margin-top: 4rem !important;
}
.mr4 {
  margin-right: 4rem !important;
}
.mb4 {
  margin-bottom: 4rem !important;
}
.ml4 {
  margin-left: 4rem !important;
}
.mx4 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.my4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mxn1 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}
.mxn2 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}
.mxn3 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}
.mxn4 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.mtn05 {
  margin-top: -0.25rem !important;
}
.mtn1 {
  margin-top: -0.5rem !important;
}
.mtn2 {
  margin-top: -1rem !important;
}
.mtn3 {
  margin-top: -2rem !important;
}
.mtn4 {
  margin-top: -4rem !important;
}

.mbn02 {
  margin-bottom: -0.2rem !important;
}
.mbn03 {
  margin-bottom: -0.3rem !important;
}

.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/**
 * Core grid component
 *
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */

/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child `Grid-cell` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.Grid {
  box-sizing: border-box;
  display: flex; /* 1 */
  flex-flow: row wrap;
  margin: 0; /* 1 */
  padding: 0; /* 1 */
}

/**
 * Modifier: center align all grid cells
 */

.Grid--alignCenter {
  justify-content: center;
}

/**
 * Modifier: right align all grid cells
 */

.Grid--alignRight {
  justify-content: flex-end;
}

/**
 * Modifier: middle-align grid cells
 */

.Grid--alignMiddle {
  align-items: center;
}

/**
 * Modifier: bottom-align grid cells
 */

.Grid--alignBottom {
  align-items: flex-end;
}

/**
 * Modifier: allow cells to equal distribute width
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex
 *    http://git.io/vllC7
 *
 *    Use `0%` to avoid bug in IE10/11 with unitless flex basis
 *    http://git.io/vllWx
 */

.Grid--fit > .Grid-cell {
  flex: 1 1 0%; /* 1 */
}

/**
 * Modifier: all cells match height of tallest cell in a row
 */

.Grid--equalHeight > .Grid-cell {
  display: flex;
}

/**
 * Modifier: gutters
 */

.Grid--withGutter {
  margin: 0 calc(-0.5 * 20px);
}

.Grid--withGutter > .Grid-cell {
  padding: 0 calc(0.5 * 20px);
}

/* Grid cell
   ========================================================================== */

/**
 * No explicit width by default. Rely on combining `Grid-cell` with a dimension
 * utility or a component class that extends 'Grid'.
 *
 * 1. Set flex items to full width by default
 * 2. Fix issue where elements with overflow extend past the
 *    `Grid-cell` container - https://git.io/vw5oF
 */

.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%; /* 1 */
  min-width: 0; /* 2 */
}

/**
 * Modifier: horizontally center one unit
 * Set a specific unit to be horizontally centered. Doesn't affect
 * any other units. Can still contain a child `Grid` object.
 */

.Grid-cell--center {
  margin: 0 auto;
}

/**
 * ChargeDescription
 *
 **/
.ChargeDescription {
  position: relative;
  box-shadow: none;
  transform: scale(0.9);
  opacity: 0.9;
  cursor: default;
  transition: box-shadow 175ms ease-in-out, opacity 175ms ease-in-out, transform 175ms ease-in-out;
  background: white;
  margin: 0.5rem auto 0;
  padding: 2rem 2rem 0;
  max-width: 24rem;

  &-form {
    padding-bottom: 6rem;
  }

  &-button {
    bottom: 2rem;
    // left: 2rem;
    // right: 2rem;
  }

  &:hover {
    transform: scale(1);
    opacity: 1;
    box-shadow: 0.5rem 0.5rem 1rem #333;
  }
}

/**
 * @define utilities
 * Sizing utilities
 */

/* Proportional widths
   ========================================================================== */

/**
 * Specify the proportional width of an object.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
 *
 * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
 *    http://git.io/vllMD
 */

/* postcss-bem-linter: ignore */

[class*='u-size'] {
  flex-basis: auto !important; /* 1 */
}

.u-size1of12 {
  width: calc(100% * 1 / 12) !important;
}

.u-size1of10 {
  width: 10% !important;
}

.u-size1of8 {
  width: 12.5% !important;
}

.u-size1of6,
.u-size2of12 {
  width: calc(100% * 1 / 6) !important;
}

.u-size1of5,
.u-size2of10 {
  width: 20% !important;
}

.u-size1of4,
.u-size2of8,
.u-size3of12 {
  width: 25% !important;
}

.u-size3of10 {
  width: 30% !important;
}

.u-size1of3,
.u-size2of6,
.u-size4of12 {
  width: calc(100% * 1 / 3) !important;
}

.u-size3of8 {
  width: 37.5% !important;
}

.u-size2of5,
.u-size4of10 {
  width: 40% !important;
}

.u-size5of12 {
  width: calc(100% * 5 / 12) !important;
}

.u-size1of2,
.u-size2of4,
.u-size3of6,
.u-size4of8,
.u-size5of10,
.u-size6of12 {
  width: 50% !important;
}

.u-size7of12 {
  width: calc(100% * 7 / 12) !important;
}

.u-size3of5,
.u-size6of10 {
  width: 60% !important;
}

.u-size5of8 {
  width: 62.5% !important;
}

.u-size2of3,
.u-size4of6,
.u-size8of12 {
  width: calc(100% * 2 / 3) !important;
}

.u-size7of10 {
  width: 70% !important;
}

.u-size3of4,
.u-size6of8,
.u-size9of12 {
  width: 75% !important;
}

.u-size4of5,
.u-size8of10 {
  width: 80% !important;
}

.u-size5of6,
.u-size10of12 {
  width: calc(100% * 5 / 6) !important;
}

.u-size7of8 {
  width: 87.5% !important;
}

.u-size9of10 {
  width: 90% !important;
}

.u-size11of12 {
  width: calc(100% * 11 / 12) !important;
}

@media (min-width: 48em) {
  /* Proportional widths: breakpoint 1 (palm)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   *
   * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
   *    http://git.io/vllMD
   */

  /* postcss-bem-linter: ignore */

  [class*='u-768-size'] {
    flex-basis: auto !important; /* 1 */
  }

  .u-768-size1of12 {
    width: calc(100% * 1 / 12) !important;
  }

  .u-768-size1of10 {
    width: 10% !important;
  }

  .u-768-size1of8 {
    width: 12.5% !important;
  }

  .u-768-size1of6,
  .u-768-size2of12 {
    width: calc(100% * 1 / 6) !important;
  }

  .u-768-size1of5,
  .u-768-size2of10 {
    width: 20% !important;
  }

  .u-768-size1of4,
  .u-768-size2of8,
  .u-768-size3of12 {
    width: 25% !important;
  }

  .u-768-size3of10 {
    width: 30% !important;
  }

  .u-768-size1of3,
  .u-768-size2of6,
  .u-768-size4of12 {
    width: calc(100% * 1 / 3) !important;
  }

  .u-768-size3of8 {
    width: 37.5% !important;
  }

  .u-768-size2of5,
  .u-768-size4of10 {
    width: 40% !important;
  }

  .u-768-size5of12 {
    width: calc(100% * 5 / 12) !important;
  }

  .u-768-size1of2,
  .u-768-size2of4,
  .u-768-size3of6,
  .u-768-size4of8,
  .u-768-size5of10,
  .u-768-size6of12 {
    width: 50% !important;
  }

  .u-768-size7of12 {
    width: calc(100% * 7 / 12) !important;
  }

  .u-768-size3of5,
  .u-768-size6of10 {
    width: 60% !important;
  }

  .u-768-size5of8 {
    width: 62.5% !important;
  }

  .u-768-size2of3,
  .u-768-size4of6,
  .u-768-size8of12 {
    width: calc(100% * 2 / 3) !important;
  }

  .u-768-size7of10 {
    width: 70% !important;
  }

  .u-768-size3of4,
  .u-768-size6of8,
  .u-768-size9of12 {
    width: 75% !important;
  }

  .u-768-size4of5,
  .u-768-size8of10 {
    width: 80% !important;
  }

  .u-768-size5of6,
  .u-768-size10of12 {
    width: calc(100% * 5 / 6) !important;
  }

  .u-768-size7of8 {
    width: 87.5% !important;
  }

  .u-768-size9of10 {
    width: 90% !important;
  }

  .u-768-size11of12 {
    width: calc(100% * 11 / 12) !important;
  }

  /* Intrinsic widths
     ========================================================================== */

  /**
   * Make an element fill the remaining space.
   *
   * 1. Be explicit to work around IE10 bug with shorthand flex - http://git.io/vllC7
   * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes - http://git.io/vllMt
   */

  .u-768-sizeFill {
    flex: 1 1 0% !important; /* 1 */
    flex-basis: 0% !important; /* 2 */
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * Distributes space based on the initial width and height of the element
   *
   * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
   */

  .u-768-sizeFillAlt {
    flex: 1 1 auto !important;
    flex-basis: auto !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-768-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }

  .u-768-sizeAuto {
    width: auto !important;
  }
}

/* Intrinsic widths
   ========================================================================== */

/**
 * Make an element shrink wrap its content.
 */

.u-sizeFit {
  flex-basis: auto !important;
}

/**
 * Make an element fill the remaining space.
 *
 * 1. Be explicit to work around IE10 bug with shorthand flex - http://git.io/vllC7
 * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes - http://git.io/vllMt
 */

.u-sizeFill {
  flex: 1 1 0% !important; /* 1 */
  flex-basis: 0% !important; /* 2 */
}

/**
 * An alternative method to make an element fill the remaining space.
 * Distributes space based on the initial width and height of the element
 *
 * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
 */

.u-sizeFillAlt {
  flex: 1 1 auto !important;
  flex-basis: auto !important;
}

/**
 * Make an element the width of its parent.
 */

.u-sizeFull {
  width: 100% !important;
}

.u-sizeAuto {
  width: auto !important;
}

.u-backgroundBlueDarkHighlight {
  background-color: #2c3941 !important;
  background-image: radial-gradient(ellipse at top, #435360 0, #2c3941 70%, #2c3941 100%);
}

.u-backgroundWhite {
  background: white !important;
}

.u-border {
  border-style: solid;
}

.u-borderBottom {
  border-bottom-style: solid;
}

.u-borderBluePrimary {
  border-color: #00bbe3;
}

.u-borderThin {
  border-width: 0.125rem;
}

.u-borderHairline {
  border-width: 0.0125rem;
}

.u-colourGrey {
  color: #999 !important;
}

.u-colourGreyLight {
  color: #ccc !important;
}

.u-colourGreyLighter {
  color: #eee !important;
}

.u-colourWhite {
  color: white !important;
}

.u-colourBluePrimary {
  color: #00bbe3 !important;
}

.u-displayNone {
  display: none !important;
}

.u-flexNoWrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.u-heightHeaderMin {
  min-height: 5.375rem;
}

.u-listStyleNone {
  list-style-type: none;
}

.u-maxWidth10 {
  max-width: 10rem !important;
}

.u-maxWidth16 {
  max-width: 16rem !important;
}

.u-maxWidth24 {
  max-width: 24rem !important;
}

.u-maxWidth36 {
  max-width: 36rem !important;
}

.u-maxWidth40 {
  max-width: 40rem !important;
}

.u-maxWidth42 {
  max-width: 42rem !important;
}

.u-maxWidth48 {
  max-width: 48rem !important;
}

.u-maxWidth56 {
  max-width: 56rem !important;
}

.u-opacity90 {
  opacity: 0.9;
}

.u-opacity70 {
  opacity: 0.7;
}

.u-posRelative {
  position: relative;
}

.u-textUppercase {
  text-transform: uppercase !important;
}

.u-textAlignCenter {
  text-align: center !important;
}

.u-textAlignLeft {
  text-align: left !important;
}

.u-textLineHeightM {
  line-height: 1.6 !important;
}

.u-textSizeXXL {
  font-size: 2rem;
}

.u-textSizeXL {
  font-size: 1.5rem;
}

.u-textSizeL {
  font-size: 1.25rem;
}

.u-textSizeM {
  font-size: 1rem;
}

.u-textSizeS {
  font-size: 0.875rem;
}

.u-textSizeXS {
  font-size: 0.75rem;
}

.u-textWeightBold {
  font-weight: 600;
}

.u-textWeightLight {
  font-weight: 300;
}

.u-textStyleItalic {
  font-style: italic;
}

.u-displayBlock {
  display: block;
}

@include grid-media-query('480') {
  .m-480-l0 {
    margin-left: 0;
  }

  .p-480-y3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .u-480-displayBlock {
    display: block !important;
  }

  .u-480-displayNone {
    display: none !important;
  }

  .u-480-textAlignLeft {
    text-align: left !important;
  }

  .u-480-textSizeXXL {
    font-size: 2rem;
  }
}

@include grid-media-query('768') {
  .m-768-b0 {
    margin-bottom: 0 !important;
  }

  .p-768-b6 {
    padding-bottom: 6rem !important;
  }

  .u-768-positionAbsolute {
    position: absolute !important;
  }
}
