.pn-header {
  padding: 1.1875rem 0;
  position: relative;

  @include grid-media-query('large-tablet-and-up') {
    padding: 2.5em 0;
  }
}

.pn-header__inner {
  margin: 0 auto;
}

.pn-header__logo {
  max-width: 100%;
  width: 100%;

  @include grid-media-query('small-tablet-and-up') {
    max-width: 25rem;
    display: block;
    margin: auto;
  }
}
