// Library settings
$push: true;
$pull: true;

// Default breakpoints, which can be overridden here
$breakpoints: (
  'palm' '(max-width: 29.99em)',
  'small-tablet' '(min-width: 30em) and (max-width: 47.99em)',
  'small-tablet-and-up' '(min-width: 30em)',
  'large-tablet' '(min-width: 48em) and (max-width: 63.99em)',
  'large-tablet-and-up' '(min-width: 48em)',
  'desk' '(min-width: 64em)',
  '480' '(min-width: 30em)',
  '768' '(min-width: 48em)'
);

// palm:                  < 480
// small-tablet:          > 480, < 768
// small-tablet-and-up    > 480
// large-tablet           > 768, < 1024
// large-tablet-and-up    > 768
// desk                   > 1024

// Default breakpoints, which can be overridden here
$breakpoint-has-widths: (
  'palm',
  'small-tablet',
  'small-tablet-and-up',
  'large-tablet',
  'large-tablet-and-up',
  'desk'
) !default;
$breakpoint-has-push: (
  'palm',
  'small-tablet',
  'small-tablet-and-up',
  'large-tablet',
  'large-tablet-and-up',
  'desk'
) !default;
$breakpoint-has-pull: (
  'palm',
  'small-tablet',
  'small-tablet-and-up',
  'large-tablet',
  'large-tablet-and-up',
  'desk'
) !default;

$c-primary: #00bbe3;
$c-body-bg: #fafafa;
$c-contentArea-bg: #eaeaea;
$c-text-color: #4a4a4a;
$c-columnHeader-color: #adc1d2;
