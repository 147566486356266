h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 1em;
  padding: 0;
  border: 0;
  font-weight: normal;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 1rem;
  line-height: 1.5em;

  @include grid-media-query('large-tablet-and-up') {
    font-size: 1.3rem;
  }
}

.pn-text--center {
  text-align: center;
}

.pn-text__heading--h1 {
  color: $c-primary;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.75em;
  line-height: normal;

  @include grid-media-query('large-tablet-and-up') {
    font-size: 3rem;
  }
}

.pn-text__heading--h2 {
  font-size: 1.3rem;
  color: black;
  text-align: center;
  width: 100%;
  font-weight: bold;
  line-height: normal;

  @include grid-media-query('large-tablet-and-up') {
    font-size: 2rem;
  }
}

.pn-text--noMarginBottom {
  margin-bottom: 0;
}
