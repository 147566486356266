.pn-havingTrouble__textArea {
  font-size: 0.875rem;
  border-radius: 0.5em;
}

.pn-havingTrouble__textAreaBody {
  text-align: center;
  padding: 0 2rem;

  p:last-of-type {
    margin-bottom: 0;
  }
}
