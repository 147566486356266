.pn-contentArea {
  background: $c-contentArea-bg;
  padding-top: 3rem;
  padding-bottom: 4rem;
  box-sizing: border-box;
  overflow: hidden;
}

.pn-contentArea__inner {
  text-align: center !important;
  line-height: 1.5em;
}
