html,
body {
  height: 100%;
  position: relative;
}

body {
  font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
}

a,
a:visited,
a:active {
  color: $c-primary;
  text-decoration: none;
}
