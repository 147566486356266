.pn-button {
  width: 100%;
  min-height: 2em;
  line-height: 1.2em;
  font-size: 1.25em;
  padding: 0.4em 1.25em;
  background: #00bbe3;
  background: -moz-linear-gradient(top, #00bbe3 0%, #00a7d6 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #00bbe3),
    color-stop(100%, #00a7d6)
  );
  background: -webkit-linear-gradient(top, #00bbe3 0%, #00a7d6 100%);
  background: -o-linear-gradient(top, #00bbe3 0%, #00a7d6 100%);
  background: -ms-linear-gradient(top, #00bbe3 0%, #00a7d6 100%);
  background: linear-gradient(to bottom, #00bbe3 0%, #00a7d6 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00bbe3', endColorstr='#00a7d6', GradientType=0);
  border: 1px solid #00a7d6;
  border-radius: 0.25em;
  color: white;
  cursor: pointer;
  max-width: 20em;
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;

  &:hover {
    background: #00a7d6;
    background: -moz-linear-gradient(top, #00a7d6 0%, #00bbe3 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #00a7d6),
      color-stop(100%, #00bbe3)
    );
    background: -webkit-linear-gradient(top, #00a7d6 0%, #00bbe3 100%);
    background: -o-linear-gradient(top, #00a7d6 0%, #00bbe3 100%);
    background: -ms-linear-gradient(top, #00a7d6 0%, #00bbe3 100%);
    background: linear-gradient(to bottom, #00a7d6 0%, #00bbe3 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00a7d6', endColorstr='#00bbe3', GradientType=0);
  }

  &:disabled {
    border: 1px solid #7a7a7a;
    background: #7a7a7a;
    background: -moz-linear-gradient(top, #7a7a7a 0%, #9e9e9e 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #7a7a7a),
      color-stop(100%, #9e9e9e)
    );
    background: -webkit-linear-gradient(top, #7a7a7a 0%, #9e9e9e 100%);
    background: -o-linear-gradient(top, #7a7a7a 0%, #9e9e9e 100%);
    background: -ms-linear-gradient(top, #7a7a7a 0%, #9e9e9e 100%);
    background: linear-gradient(to bottom, #7a7a7a 0%, #9e9e9e 100%);
    filter: progid:dximagetransform.microsoft.gradient(startColorstr='#7a7a7a', endColorstr='#9e9e9e', GradientType=0);
  }
}

.pn-button--invitation {
  display: block;
  width: 100%;
  padding: 1rem;
  min-height: 3rem;
  background: #00bbe3;
  background: linear-gradient(to bottom, #00bbe3 0%, #1f8da3 100%);
  border: none;
  outline: none;
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  border-radius: 0.1875rem;

  &:hover {
    background: #00bbe3;
    background: linear-gradient(to top, #00bbe3 0%, #1f8da3 100%);
  }
}
