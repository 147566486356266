.pn-canvas {
  position: relative;
  height: 100%;

  .grid--max-width {
    @include grid-media-query('desk') {
      width: initial;
      max-width: 60em;
      display: block;
      margin: 0 auto;
    }
  }
}

.pn-canvas__inner {
  display: table;
  min-height: 100%;
  height: 100%;
}

.pn-canvas__innerCell {
  display: table-cell;
  vertical-align: middle;
}

.pn-canvas__section {
  background: $c-body-bg;
  box-sizing: border-box;
}
